





















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'IntegrationManagementSectionComment' })
export default class IntegrationManagementSectionComment extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly value!: string

  @Prop({
    type: Boolean,
    default: false,
  }) readonly loading!: false

  @Prop({
    type: String,
    default: '',
  }) readonly error!: string

  internalError = ''
  maxLength = 2048 // server deafult, see 'created' hook

  get hasError (): boolean {
    return !!this.error || !!this.internalError
  }

  get errorMsg (): string {
    return this.error || this.internalError
  }

  created () {
    const maxLength = window.FEATURES.max_integration_comment_length
    // why undef? because it can technically be 0
    maxLength !== undefined && (this.maxLength = maxLength)
  }

  /**
   * Updates component model and handles internal error if needed
   * @param {string} value user input
   */
  handleInput (value: string): void {
    // update the model anyway
    this.$emit('input', value)

    // check for errors
    if (value.length <= this.maxLength) {
      this.internalError = ''
    } else {
      const key = 'common.validationErrors.maxLength'
      const params = { to: this.maxLength }
      this.internalError = this.$t(key, params).toString()
      this.$emit('update:error', this.internalError)
    }
  }
}
